import React from "react";
// import styled from "styled-components";
import "../../pages/style.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Container, Row, Col } from "react-grid-system";

//const colour = require("../../assets/colour");

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: "rgba(36,37,38,0.6)",
    color: "#fff",
    borderRadius: "1.5vh",
    borderColor: "#b643e6",
    borderWidth: "1px",
    fontFamily: `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
    color: "#fff",
    fontFamily: `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
  },
  title: {
    fontSize: 30,
    fontWeight: "4pt",
    color: "#fff",
    fontFamily: `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
  },
  pos: {
    marginBottom: 12,
    color: "#fff",
    fontFamily: `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
  },
  button: {
    color: "#FFF",
    borderRadius: "1.2vh",
    borderColor: "#b643e6",
    borderWidth: "1px",
    fontFamily: `"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
  },
});

function PrizesCard() {
  const classes = useStyles();
  return (
    <Container>
      <Row className="justify-content-md-center" lg={4} md={3} s={12}>
        <Col
          style={{
            paddingBottom: "2vh",
            paddingTop: "2vh",
            flexDirection: "column",
          }}
        >
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                🔥Double Slash Prize🔥
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                A total prize pool of INR 30,00,000 in benefits for winners and
                participants.
              </Typography>
              <Typography className={classes.pos}>
                Cash Awards upto INR 10,000 and Amazon Gift Cards for Top 3
                Teams
              </Typography>
              <a href="https://www.facebook.com/379102212921998/posts/985049165660630/">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
        <Col style={{ paddingBottom: "2vh", paddingTop: "2vh" }}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                🚀PrepBytes Prize🚀
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                T-Shirts, Sippers and Exciting Merch for Winning Teams in
                Various Tracks
              </Typography>
              <Typography className={classes.pos}>
                PrepBytes Subscription Vouchers worth INR 2,00,000 for all
                participants
              </Typography>
              <a href="https://www.facebook.com/379102212921998/posts/985049165660630/">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
        <Col style={{ paddingBottom: "2vh", paddingTop: "2vh" }}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                🎁Devfolio Schwags🎁
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Exclusive Devfolio for Winning Teams
              </Typography>
              <Typography className={classes.pos}>
                T-shirts and much more
              </Typography>
              <br />
              <br />
              <a href="https://www.facebook.com/379102212921998/posts/985049165660630/">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-md-center" lg={4} md={3} s={12}>
        <Col
          style={{
            paddingBottom: "2vh",
            paddingTop: "2vh",
            flexDirection: "column",
          }}
        >
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                🎉Polygon Season Prize🎉
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Cash Awards upto INR 10000 for Best Ethereum Hack
              </Typography>
              <Typography className={classes.pos}>
                Cash Awards upto INR 15000 for Best Ethereum-Polygon Hack along
                with eligibility for internship/full-time role interviews and a
                chance to land seed funding of upto 5000$.
              </Typography>
              <a href="https://www.notion.so/Polygon-Devfolio-Hackathon-Season-Prize-de8961d5eeff4780963749da0b75037c">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
        <Col style={{ paddingBottom: "2vh", paddingTop: "2vh" }}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                ⚡Tezos Season Prize⚡
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Cash Awards ₹20,000 for best Dapp built on Tezos and ₹25,000 for
                building a Flutter Tezos Wallet App.
              </Typography>
              <Typography className={classes.pos}>
                Continuity grant opportunity up to 5,000 USD for an outstanding
                project.
              </Typography>
              <a href="https://www.notion.so/Tezos-Devfolio-Hackathon-Season-Prize-e90b6811b0df43e5a7dadf534fc000ff">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
        <Col style={{ paddingBottom: "2vh", paddingTop: "2vh" }}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                💥Portis Season Prize💥
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Cash Awards upto ₹15000 for best Dapp built on Portis
              </Typography>
              <Typography className={classes.pos}>
                Build a DApp that anybody can use. Your parents, your highschool
                history teacher, anyone to grab a chance to win this prize.
              </Typography>
              <br />
              <a href="https://www.notion.so/Portis-by-Shapeshift-Devfolio-Hackathon-Season-Prize-e198dfd094a9411b8cd0f80d76a04a90">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center" lg={4} md={3} s={12}>
        <Col
          style={{
            paddingBottom: "2vh",
            paddingTop: "2vh",
            flexDirection: "column",
          }}
        >
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                ✨Celo Season Prize✨
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Cash Awards ₹20,000 for best
              </Typography>
              <Typography className={classes.pos}>
                Dapp built on Celo
              </Typography>
              <br />
              <br />
              <a href="https://www.notion.so/Celo-Devfolio-Hackathon-Season-Prize-8b98dac17f134abeae863d5d98c01ff0">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
        <Col style={{ paddingBottom: "2vh", paddingTop: "2vh" }}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                💎echoAR Schwags💎
              </Typography>

              <Typography className={classes.pos} color="textSecondary">
                1 month free business tier access
              </Typography>
              <Typography className={classes.pos}>
                worth $89 for all participants
              </Typography>
              <br />
              <br />
              <a href="https://www.facebook.com/379102212921998/posts/985049165660630/">
                <Button
                  size="small"
                  className={classes.button}
                  variant="outlined"
                >
                  Learn More
                </Button>
              </a>
            </CardContent>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
}

export default PrizesCard;
