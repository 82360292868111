const bronzeData = [
  {
    id: 1,
    className: "logo_small",
    link: "https://www.jetbrains.com/",
    image: require("../assets/sponsors/echoar.png").default,
    alt: "Echo AR bronze sponsor for DoubleSlash",
  },
  {
    id: 2,
    className: "logo_small",
    link: "https://www.jetbrains.com/",
    image: require("../assets/sponsors/jetbrains.png").default,
    alt: "Jet Brains bronze sponsor for DoubleSlash",
    style: { width: "150px" },
  },
  {
    id: 3,
    className: "logo_small",
    link: "https://balsamiq.com/",
    image: require("../assets/sponsors/balsamiq.png").default,
    alt: "Balsamiq bronze sponsor for DoubleSlash",
  },
  {
    id: 4,
    className: "logo_small",
    link: "https://www.taskade.com/",
    image: require("../assets/sponsors/taskade.png").default,
    alt: "Taskade bronze sponsor for DoubleSlash",
    style: { width: "150px" },
  },
  {
    id: 5,
    className: "logo_small",
    link: "https://codingblocks.com/",
    image: require("../assets/sponsors/codingblocks.png").default,
    alt: "Coding Blocks bronze sponsor for DoubleSlash",
  },
  {
    id: 6,
    className: "logo_small",
    link: "https://1password.com/",
    image: require("../assets/sponsors/1password.png").default,
    alt: "1 password bronze sponsor for DoubleSlash",
    style: { width: "150px" },
  },
  {
    id: 7,
    className: "logo_small",
    link: "https://draftbit.com/",
    image: require("../assets/sponsors/draftbit.png").default,
    alt: "draftbit bronze sponsor for DoubleSlash",
  },
  {
    id: 8,
    className: "logo_small",
    link: "https://testmail.app/",
    image: require("../assets/sponsors/testmail.svg").default,
    alt: "testmail app bronze sponsor for DoubleSlash",
  },
  {
    id: 9,
    className: "logo_small",
    link: "https://nostarch.com/",
    image: require("../assets/sponsors/image.png").default,
    alt: "nostarch bronze sponsor for DoubleSlash",
  },
  {
    id: 10,
    className: "logo_small",
    link: "https://www.roostoo.com/",
    image: require("../assets/sponsors/roostoo.png").default,
    alt: "Roostoo bronze sponsor for DoubleSlash",
  },
  {
    id: 11,
    className: "logo_small",
    link: "https://hoverrobotix.com/",
    image: require("../assets/sponsors/hoverRobotix.png").default,
    alt: "Hover Robotix bronze sponsor for DoubleSlash",
  },
  {
    id: 12,
    className: "logo_small",
    link: "https://www.hackathonsinternational.com/",
    image: require("../assets/sponsors/HackathonsInternational1.png").default,
    alt: "Hackathons International bronze sponsor for DoubleSlash",
  },
  {
    id: 13,
    className: "logo_small",
    link: "https://interviewbuddy.in/",
    image: require("../assets/sponsors/interviewBuddy.png").default,
    alt: "Interview Buddy bronze sponsor for DoubleSlash",
  },
  {
    id: 14,
    className: "logo_small",
    link: "https://aichamp.in/",
    image: require("../assets/sponsors/download.png").default,
    alt: "Aichamp bronze sponsor for DoubleSlash",
  },
  {
    id: 15,
    className: "logo_small",
    link: "http://karoverify.xyz/",
    image: require("../assets/sponsors/karoverify2.png").default,
    alt: "Karo Verify bronze sponsor for DoubleSlash",
  },
  {
    id: 16,
    className: "logo_small",
    link: "https://rosenfeldmedia.com/",
    image: require("../assets/sponsors/Rosenfeld.png").default,
    alt: "Rosenfeld bronze sponsor for DoubleSlash",
  },
  {
    id: 17,
    className: "logo_small",
    link: "https://www.stickermule.com/",
    image: require("../assets/sponsors/sticker-mule.svg").default,
    alt: "StickerMule bronze sponsor for DoubleSlash",
  },
  {
    id: 18,
    className: "logo_small",
    link: "https://replit.com/",
    image: require("../assets/sponsors/replit.svg").default,
    alt: "replit bronze sponsor for DoubleSlash",
  },
  {
    id: 19,
    className: "logo_small",
    link: "https://www.framer.com/",
    image: require("../assets/sponsors/framer.png").default,
    alt: "Framer bronze sponsor for DoubleSlash",
  },
  {
    id: 20,
    className: "logo_small",
    link: "https://gen.xyz/",
    image: require("../assets/sponsors/xyzdomain.png").default,
    alt: "XYZ domain bronze sponsor for DoubleSlash",
  },
  {
    id: 21,
    className: "logo_small",
    link: "https://www.linode.com/",
    image: require("../assets/sponsors/linode.png").default,
    alt: "Linode bronze sponsor for DoubleSlash",
  },
  {
    id: 22,
    className: "logo_small",
    link: "https://www.wolframalpha.com/",
    image: require("../assets/sponsors/Wolfram1.jpg").default,
    alt: "Wolfram bronze sponsor for DoubleSlash",
  },
  {
    id: 23,
    className: "logo_small",
    link: "https://www.codingminutes.com/",
    image: require("../assets/sponsors/codingminutes.png").default,
    alt: "Coding Minutes bronze sponsor for DoubleSlash",
  },
  {
    id: 24,
    className: "logo_small",
    link: "https://www.qoom.io/",
    image: require("../assets/sponsors/qoom.png").default,
    alt: "Qoom bronze sponsor for DoubleSlash",
  },
  {
    id: 25,
    className: "logo_small",
    link: "https://www.axure.com/",
    image: require("../assets/sponsors/axure.PNG").default,
    alt: "Axure bronze sponsor for DoubleSlash",
  },
];

const silverData = [
  {
    id: 1,
    className: "logo-mid",
    link: "https://www.echoar.xyz",
    image: require("../assets/sponsors/echoAR-silver.png").default,
    alt: "EchoAR silver sponsor for DoubleSlash",
    style: { width: "300px" },
  }
];

const scriptData = [
  {
    id: 1,
    className: "logo-script",
    link: "https://www.scriptindia.org/",
    image: require("../assets/sponsors/script.png").default,
    alt: "Script Foundation education partner for DoubleSlash",
  },
];

const webwizData = [
  {
    id: 2,
    className: "logo-webwiz",
    link: "https://webwiz-portfolio.web.app/",
    image: require("../assets/sponsors/webwizlogo.png").default,
    alt: "Webwiz the lead organizing team of DoubleSlash",
  },
];

const rourkData = [
  {
    id: 3,
    className: "logo-rourkela360",
    link: "https://www.instagram.com/rourkela360/",
    image: require("../assets/sponsors/rourkela360.png").default,
    alt: "Rourkela 360 media partner of DoubleSlash",
  },
];

const goldData = [
  {
    id: 1,
    className: "logo-mid",
    link: "https://www.portis.io/",
    image: require("../assets/sponsors/portisnew.png").default,
    alt: "Portis silver sponsor for Hackodisha",
    style: { width: "300px" },
    xl: 4,
  },
  {
    id: 2,
    className: "logo-mid",
    link: "https://celo.org/",
    image: require("../assets/sponsors/celo1.png").default,
    alt: "Celo silver sponsor for Hackodisha",
    style: { width: "300px" },
    xl: 4,
  },
  {
    id: 3,
    className: "logo-mid",
    link: "https://tezos.com/",
    image: require("../assets/sponsors/tezos.png").default,
    alt: "Tezos silver sponsor for Hackodisha",
    style: { width: "300px" },
    xl: 4,
  },
  {
    id: 4,
    className: "logo-mid",
    link: "https://polygon.technology/",
    image: require("../assets/sponsors/poly1.png").default,
    alt: "Polygon silver sponsor for Hackodisha",
    style: { width: "350px" },
    xl: 6,
  },
  {
    id: 5,
    className: "logo-mid",
    link: "https://www.prepbytes.com/",
    image: require("../assets/sponsors/prepbytes.png").default,
    alt: "Polygon silver sponsor for Hackodisha",
    style: { width: "350px" },
    xl: 6,
  },
];
const platinumData = [
  {
    id: 1,
    className: "logo-large",
    link: "https://www.computer.org/",
    image: require("../assets/sponsors/ieee-cs-logo.png").default,
    alt: "IEEE CS Chapter platinum sponsor for Hackodisha",
  },
  {
    id: 2,
    className: "logo-large",
    link: "https://devfolio.co/home/",
    image: require("../assets/sponsors/devfolio1.png").default,
    alt: "Devfolio platinum sponsor for Hackodisha",
  },
  {
    id: 3,
    className: "logo-large",
    link: "https://yp.ieee.org/",
    image: require("../assets/sponsors/ieee yp.png").default,
    alt: "IEEE YP sponsor for Hackodisha",
  },
];

export {
  bronzeData,
  silverData,
  goldData,
  platinumData,
  webwizData,
  scriptData,
  rourkData,
};
