import React from "react";
// import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import styled from "styled-components";
import "./style.css";
import { Row, Col } from "react-grid-system";
import Raisebox from "../components/raisebox/raisebox";
import Card from "../components/card";
// import {bronzeData, silverData, goldData, platinumData, scriptData, webwizData, rourkData} from "./sponData";
import { goldData, platinumData, silverData } from "./sponData";
const colour = require("../assets/colour");

// const useStyles = makeStyles((theme) => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
//   extendedIcon: {
//     marginRight: theme.spacing(1),
//   },
// }));

export const LogosBronze = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (min-width: 768px) and (max-width: 939px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 940px) and (max-width: 1300px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

// function bronzeCard(bronzeData) {
//   return (
//      <Card
//       key={bronzeData.id}
//       className={bronzeData.className}
//       link={bronzeData.link}
//       img={bronzeData.image}
//       alt={bronzeData.alt}
//     />
//   );
// };

function makeCard(silverData) {
  return (
    <Col sm={12} md={6} lg={6} xl={12}>
      <Card
        key={silverData.id}
        className={silverData.className}
        link={silverData.link}
        img={silverData.image}
        alt={silverData.alt}
      />
    </Col>
  );
}

function goldCard(goldData) {
  return (
    <Col sm={12} md={6} lg={6} xl={goldData.xl}>
      <Card
        style={{ paddingBottom: "3vh" }}
        key={goldData.id}
        className={goldData.className}
        link={goldData.link}
        img={goldData.image}
        alt={goldData.alt}
      />
    </Col>
  );
}

function platinumCard(platinumData) {
  return (
    <Col sm={12} md={6} lg={6} xl={4}>
      <Card
        key={platinumData.id}
        className={platinumData.className}
        link={platinumData.link}
        img={platinumData.image}
        alt={platinumData.alt}
      />
    </Col>
  );
}

// function platinumCard(platinumData) {
//   return (

//      <Card
//       key={platinumData.id}
//       className={platinumData.className}
//       link={platinumData.link}
//       img={platinumData.image}
//       alt={platinumData.alt}
//     />

//   );
// }

// function scriptCard(scriptData) {
//   return (

//      <Card
//       key={scriptData.id}
//       className={scriptData.className}
//       link={scriptData.link}
//       img={scriptData.image}
//       alt={scriptData.alt}
//     />

//   );
// }

// function webwizCard(webwizData) {
//   return (

//      <Card
//       key={webwizData.id}
//       className={webwizData.className}
//       link={webwizData.link}
//       img={webwizData.image}
//       alt={webwizData.alt}
//     />

//   );
// }

// function rourkCard(rourkData) {
//   return (

//      <Card
//       key={rourkData.id}
//       className={rourkData.className}
//       link={rourkData.link}
//       img={rourkData.image}
//       alt={rourkData.alt}
//     />

//   );
// }

function Sponsors() {
  // const classes = useStyles();
  return (
    <div>
      {/* Platinum Sponsor */}
      <Raisebox style={{ minHeight: "20vh", paddingBottom: "3vh" }}>
        <h1 className="sponsors_h1">
          Platinum{" "}
          <span style={{ color: colour.highlightedColour }}> Sponsors </span>
        </h1>
        <Row>{platinumData.map(platinumCard)}</Row>
      </Raisebox>

      {/* Gold Sponsor */}
      <Raisebox style={{ minHeight: "20vh", paddingBottom: "3vh" }}>
        <h1 className="sponsors_h1">
          Gold{" "}
          <span style={{ color: colour.highlightedColour }}> Sponsors </span>
        </h1>
        <Row>{goldData.map(goldCard)}</Row>
        {/* <div>
          <Button variant="outlined" size="large" color="primary" className={classes.margin}>  Sponsor Us! </Button>
        </div> */}
      </Raisebox>
      {/* Silver Sponsor */}
      <Raisebox style={{ minHeight: "20vh", paddingBottom: "3vh" }}>
        <h1 className="sponsors_h1">
          Silver{" "}
          <span style={{ color: colour.highlightedColour }}> Sponsors </span>
        </h1>
        <Row>{silverData.map(makeCard)}</Row>
      </Raisebox>

      {/* Bronze Sponsors */}
      {/* <Raisebox style={{minHeight:"20vh", paddingBottom:"3vh"}}>
        <h1 className="sponsors_h1">
          Bronze
          <span style={{ color: "#ff0000" }}> Sponsors </span>
        </h1>
        <LogosBronze>
          {bronzeData.map(bronzeCard)}
        </LogosBronze>
      </Raisebox> */}

      {/* Partners */}
      {/* <Raisebox style={{minHeight:"20vh",paddingTop:"50px"}}>
        <Row>
          <Col sm={12} md={12} lg={4} xl={4}>
          {scriptData.map(scriptCard)}

                <h3 className="sponsors_h3">
          Education <span style={{ color: "#ff0000" }}> Partner </span>
        </h3>
        </Col>
        <Col sm={12} md={12} lg={4} xl={4}>
        {webwizData.map(webwizCard)}


          <h3 className="sponsors_h3">
          Organizing <span style={{ color: "#ff0000" }}> Team </span>
        </h3>
          </Col>
          <Col sm={12} md={12} lg={4} xl={4}>
          {rourkData.map(rourkCard)}


          <h3 className="sponsors_h3">
          Media <span style={{ color: "#ff0000" }}> Partner </span>
        </h3>
          </Col>
        </Row>
      </Raisebox> */}
    </div>
  );
}

export default Sponsors;
