import React from "react";
import styled from "styled-components";
const colour = require("../../assets/colour");

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoText = styled.h1`
  color: #fff;
`;

export function Logo(props) {
  return (
    <LogoWrapper>
      <a href="/" rel="noopener noreferrer" style={{textDecoration: "none"}}>
      <LogoText>
       {"/"}<span style={{ color: colour.highlightedColour }}>{"/"}</span>{'doubleSlash'}
      </LogoText>
      </a>
    </LogoWrapper>
  );
}
