const TrackData = [
  {
    trackName: "Blockchain",
    trackImage: require("../../assets/icons/blockchain.png").default,
    trackImageAlt: "blockchain_track",
  },
  {
    trackName: "Healthcare",
    trackImage: require("../../assets/icons/health.png").default,
    trackImageAlt: "health_track",
  },
  {
    trackName: "Women Safety",
    trackImage: require("../../assets/icons/woman.png").default,
    trackImageAlt: "WomenSafety_track",
  },
  {
    trackName: "Agriculture",
    trackImage: require("../../assets/icons/agriculture.png").default,
    trackImageAlt: "agri_track",
  },
  {
    trackName: "Defence",
    trackImage: require("../../assets/icons/defence.png").default,
    trackImageAlt: "defence_track",
  },
  {
    trackName: "Education",
    trackImage: require("../../assets/icons/education.png").default,
    trackImageAlt: "edu_track",
  },
  {
    trackName: "Cyber Security",
    trackImage: require("../../assets/icons/cyber-security.png").default,
    trackImageAlt: "cyber_track",
  },
  {
    trackName: "Slash (Open Innovation)",
    trackImage: require("../../assets/icons/slash.png").default,
    trackImageAlt: "slash_track",
  },
];

export { TrackData };
