import React from "react";
import { Container, Row, Col } from "react-grid-system";
import "./style.css";
import Raisebox from "../components/raisebox/raisebox";
const colour = require("../assets/colour");

function About() {
  return (
    <div>
      <Raisebox style={{ minHeight: "20vh", display: "flex" }}>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6}>
              {/* <div className="about_heading">
                <h1 className="about_h1">
                  Double <span style={{ color: colour.highlightedColour }}> Slash </span>{" "}
                </h1> 
              </div> */}
              <img
                className="about-img"
                src={require("../assets/images/double-slash.png").default}
                alt="Doubleslash"
              />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <h2 className="about_h2">
                Join in with us on a
                <span style={{ color: colour.highlightedColour }}>
                  {" "}
                  48 hours{" "}
                </span>
                journey of innovation and imagination but be warned it's going
                to be a rough three day journey. We shall start the journey with
                an intense round of reasoning and validation with peers
                following up with late night coding and testing, and finally,
                the journey ends with a thorough validation by the best. So,
                come along with us on{" "}
                <span style={{ color: colour.highlightedColour }}>
                  {" "}
                  Double Slash{" "}
                </span>{" "}
                organized by the
                <span style={{ color: colour.highlightedColour }}>
                  {" "}
                  IEEE JU Student Branch{" "}
                </span>
                . A 72 hours marathon of executing unique ideas. Choose from the
                <span style={{ color: colour.highlightedColour }}>
                  {" "}
                  seven tracks{" "}
                </span>{" "}
                or create your own under the
                <span style={{ color: colour.highlightedColour }}>
                  {" "}
                  Slash track{" "}
                </span>
                Slash track and turn your idea into reality. Get yourself tested
                beyond limits by the best in the industry. Let's
                <span style={{ color: colour.highlightedColour }}>
                  {" "}
                  Code Commit and Create!
                </span>
              </h2>
            </Col>
          </Row>
        </Container>
      </Raisebox>
    </div>
  );
}

export default About;
