import React from "react";
// import styled from "styled-components";
import "./style.css";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
//import Button from "@material-ui/core/Button";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Container, Row, Col } from "react-grid-system";
import Raisebox from "../components/raisebox/raisebox";
const colour = require("../assets/colour");

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

function Speakers() {
  const classes = useStyles();

  return (
    <Raisebox style={{ minHeight: "70vh", display: "block" }}>
      <div className="events_container">
        <h1 className="events_h1">
          OUR{" "}
          <span style={{ color: colour.highlightedColour }}> SPEAKERS </span>
        </h1>
      </div>
      <div>
       

        <Container>
          <Row className="justify-content-md-center">
            <Col
              style={{
                paddingBottom: "8vh",
                paddingTop: "5vh",
                flexDirection: "column",
              }}
              lg={4}
              xs={12}
            >
              <Card className={classes.root} style={{ borderRadius: "1.5vh" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="250"
                    image="https://raw.githubusercontent.com/ieee-ju/storage/main/20041231.png"
                    title="Contemplative Reptile"
                  />
                  <CardContent style={{ background: "#242526" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{
                        background: "#242526",
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    >
                      Krish Naik
                    </Typography>
                    <Typography
                      style={{
                        background: "#242526",
                        color: "#fff",
                        fontStyle: "italic",
                        fontSize: "1rem",
                      }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {" "}
                      Co Founder At iNeuron.ai And Youtuber With 380K+
                      Subscribers{" "}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions
                  style={{
                    justifyContent: "space-around",
                    display: "flex",
                    background: "#242526",
                    color: "#fff",
                  }}
                >
                  <div>
                    {" "}
                    <a
                      href="https://www.linkedin.com/in/naikkrish/"
                      style={{
                        paddingLeft: "15 px",
                      }}
                    >
                      <LinkedInIcon style={{ color: "#fffff" }}></LinkedInIcon>
                    </a>
                    <a
                      href="https://www.youtube.com/user/krishnaik06"
                      style={{
                        paddingLeft: "15px",
                      }}
                    >
                      <YouTubeIcon style={{ color: "#fffff" }}></YouTubeIcon>
                    </a>
                  </div>
                </CardActions>
              </Card>
            </Col>
            <Col style={{ paddingBottom: "8vh", paddingTop: "5vh" }}>
              <Card className={classes.root} style={{ borderRadius: "1.5vh" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="250"
                    image="https://raw.githubusercontent.com/ieee-ju/storage/main/akhil.jpeg"
                    title="Contemplative Reptile"
                  />
                  <CardContent style={{ background: "#242526" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{
                        background: "#242526",
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    >
                      Akhil Bharti
                    </Typography>
                    <Typography
                      style={{
                        background: "#242526",
                        color: "#fff",
                        fontStyle: "italic",
                        fontSize: "1rem",
                      }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {" "}
                      Senior Blockchain Engineer{" "}
                    </Typography>
                    <Typography
                      style={{
                        background: "#242526",
                        color: "#fff",
                        fontStyle: "italic",
                        fontSize: "1rem",
                      }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {" "}
                      Tezos India{" "}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions
                  style={{
                    justifyContent: "space-around",
                    display: "flex",
                    background: "#242526",
                    color: "#fff",
                  }}
                >
                  <div>
                    {" "}
                    <a
                      href="https://www.linkedin.com/in/akhil-bharti-17161272/"
                      style={{
                        paddingLeft: "15 px",
                      }}
                    >
                      <LinkedInIcon style={{ color: "#fffff" }}></LinkedInIcon>
                    </a>
                  </div>
                </CardActions>
              </Card>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    </Raisebox>
  );
}

export default Speakers;