import React from "react";
import styled from "styled-components";
import Raisebox from "../components/raisebox/raisebox";
import "./style.css";
import { Container, Row, Col } from "react-grid-system";
import { TimerCard } from "../components/timer";
const colour = require("../assets/colour");

export const Buttons = styled.div`
  margin-left: -06vw;
  margin-top: 2vh;
  @media only screen and (min-width: 381px) and (max-width: 768px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 380px) {
    margin: 0 auto;
    overflow: hidden;
  }
`;

export const Button1 = styled.button`
  height: 46px;
  width: 315px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: black;
  @media only screen and (min-width: 708px) {
    margin-left: 10px;
    margin-bottom: 15px;
  }
`;

export const Button2 = styled.div`
  cursor: pointer;
`;

export const Discord = styled.img`
  height: 43px;
  width: 140px;
`;

function Home() {
  return (
    <>
      <Raisebox style={{ minHeight: "20vh", marginTop: "5vh" }}>
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <img
              style = {{float:"left", padding : "0 0 0 15%"}}
                className="map"
                src={require("../assets/images/banner-doubleslash.png").default}
                alt="Doubleslash"
              />
            </Col>
            <Col sm={12} md={6} lg={6} xl={6}>
              <h1 className="home_h1">
                {/*<span className="home_h2_span">IEEE Jadavpur University Student Branch <br/> Presents <br/> </span>*/}
                <a
                  href="https://ieee-jaduniv.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    style={{ height: "50px", width: "300px" }}
                    src={require("../assets/images/RAW_Logo.png").default}
                    alt="Doubleslash"
                  />{" "}
                </a>
                <span className="home_h2_span">
                  <br />
                  Presents
                  <br />
                </span>
                <br />
                <span className="home_h1_span"> {"/"}</span>
                <span
                  className="home_h1_span"
                  style={{ color: colour.highlightedColour }}
                >
                  {"/"} 
                </span>
                <span className="home_h1_span">{"doubleSlash"} </span>
                <br />
                Code.
                <span style={{ color: colour.highlightedColour }}>Commit</span>
                .Create{" "}
              </h1>

              <h1 className="about_h1">
                <span style={{ color: colour.highlightedColour }}></span> 9{" "}
                <sup>th</sup> - {" "}
                <span style={{ color: colour.highlightedColour }}>
                  {" "}
                  11 <sup>th</sup>
                </span>{" "}
                July
              </h1>
              <Buttons>
                <Button2
                  id="devfolio-apply-now"
                  className="apply-button"
                  data-hackathon-slug="doubleslash-ieee-jaduniv"
                  data-button-theme="light"
                ></Button2>
                <a
                  href="https://discord.g/vcBCqn6N"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button1>
                    <Discord
                      src={require("../assets/images/discord.png").default}
                      alt="DISCORD"
                    />
                  </Button1>
                </a>
              </Buttons>
            </Col>
          </Row>
        </Container>
      </Raisebox>
      <Raisebox style={{ minHeight: "20vh" }}>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className="prizes_h2">
                Hackathon will be{" "}
                <span style={{ color: colour.highlightedColour }}>live</span>{" "}
                in!
              </h2> 
              <TimerCard
                time={1625808600} // add UNIX TimeStamp from https://www.unixtimestamp.com/index.php
              />
            </Col>
          </Row>
        </Container>
      </Raisebox>
    </>
  );
}

export default Home;
