import React from "react";
import Raisebox from "../components/raisebox/raisebox.js";
import TracksCard from "../components/home-cards/HomeCard.jsx";
import { Container, Row, Col } from "react-grid-system";
const colour = require("../assets/colour");

function home2() {
  return (
    <>
      <meta name="description" content="Lets solve the problems together" />
      <Raisebox>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6} xl={6}>
              <div className="about_heading track_bg_img">
                <h1 className="about_h1 ">
                  Let your{" "}
                  <span style={{ color: colour.highlightedColour }}>
                    {" "}
                    ideas{" "}
                  </span>{" "}
                  take wings and fly into{" "}
                  <span style={{ color: colour.highlightedColour }}>
                    {" "}
                    reality{" "}
                  </span>
                </h1>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <TracksCard style={{ marginLeft: "10px" }} />
            </Col>
          </Row>
        </Container>
      </Raisebox>
    </>
  );
}

export default home2;
