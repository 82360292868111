import React from "react";
import Raisebox from "../components/raisebox/raisebox";
import Faq from "react-faq-component";
import { Container, Row, Col } from "react-grid-system";
const colour = require("../assets/colour");

const data1 = {
  rows: [
    {
      title: "What is Double Slash? What is the Timeline ?",
      content:
        "Double Slash is a coding marathon where you build something with tech by working with people. It isn’t about hacking into a system, it is about building something together, It is about learning a great deal and creating something exciting in the process. Double Slash starts from 9th July 10:30 AM and your final submission must be by 11th July 11 AM.",
    },
    {
      title: "How to register ?",
      content:
        "You can register by clicking on the registeration link, click on the 'Apply with Devfolio' button which is there on the landing banner and on clicking it you will have to create an account and fill all the required input fields",
    },
    {
      title: "How much does it cost ?",
      content:
        "Zero. Zip. Zilch. Nada. Nothing. Registration for Double Slash is completely free for all undergraduate engineering students.",
    },
  ],
};

const data2 = {
  rows: [
    {
      title: " Can we apply as a team ?",
      content:
        "Yes! This hackathon is strictly a team competition. We believe that you’re even stronger as a team than you are apart.",
    },
    {
      title: "What is the maximum team size ?",
      content:
        "A Team can have minimum of 2 members to a maximum of 5 members.",
    },
    {
      title: "Who can participate and what are the prerequisites ?",
      content:
        "If you are a undergrad engineering student, and have a passion for tech we'd love to have you at Double Slash. While experience in coding and programming is a huge plus, teams will also need people with strong presentation skills and brilliant ideas.",
    },
  ],
};

const styles = {
  bgColor: "#18191a",
  titleTextColor: "black",
  rowTitleColor: colour.highlightedColour,
  rowContentColor: "white",
  arrowColor: "white",
  height: "60px",
};

function home2() {
  return (
    <>
      <meta
        name="description"
        content="Do I need to pay any money to register for the Hackathon?"
      />
      <Raisebox style={{ minHeight: "70vh", display: "block" }}>
        <h1 className="faq_heading">
          Have any{" "}
          <span style={{ color: colour.highlightedColour }}> questions?</span>
        </h1>
        <Container>
          <Row className="faq_content">
            <Col sm={12} md={12} lg={6} xl={6}>
              <Faq data={data1} styles={styles} />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <Faq data={data2} styles={styles} />
            </Col>
          </Row>
        </Container>
      </Raisebox>
    </>
  );
}

export default home2;
