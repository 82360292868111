import React from "react";
import "../../pages/style.css";
import { Row, Col } from "react-grid-system";
import { TrackData} from "./TrackData";

function TrackCard(TrackData) {

  return (
    <Col sm={6} md={6} lg={6} xl={6}>
        <div className="tracks_card">
          <div className="card_icon">
            <img
              src={TrackData.trackImage}
              alt={TrackData.trackImageAlt}
              className="tracks_icon"
            />
          </div>
          <h4 className="tracks_h4">{TrackData.trackName}</h4>
        </div>
    </Col>
  );
}

function TracksCard() {
  return (
    <div style={{ marginTop: "2.5%",marginBottom: "2.5%" }}>
      <Row>
        {TrackData.map(TrackCard)}
      </Row>
    </div>
  );
}

export default TracksCard;
