import React from "react";
import Raisebox from "../components/raisebox/raisebox";
import './style.css';
const colour = require("../assets/colour");

function Contacts() {
  return (
    <Raisebox style={{minHeight:"20vh", paddingBottom:"2vh"}}>
      <div className="prizes_container">
        <h1 className="prizes_h1">
          CONTACT <span style={{ color: colour.highlightedColour }}> US</span>
       </h1> 
      </div>
      <h2 className="contact_h2">Call us at <a href="tel:+91 6296709930">+91 6296709930</a> or <a href="tel:+91 7278387774">+91 7278387774</a></h2>
      <h2 className="contact_h2">Email us at <a href="mailto:jaduniv.ieee@gmail.com">jaduniv.ieee@gmail.com</a></h2> 
    </Raisebox>
  );
}

export default Contacts;
