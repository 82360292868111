import React from "react";
import Raisebox from "../components/raisebox/raisebox";
import PrizesCard from "../components/prizes/index.jsx"; 
import './style.css';
const colour = require("../assets/colour");

function Prizes() {
  return (
    <Raisebox>
      <div className="prizes_container">
        <h1 className="prizes_h1">
          EXCITING <span style={{ color: colour.highlightedColour }}> PRIZES</span>
       </h1> </div>
       {/* <h2 className="prizes_h2">To be revealed soon</h2> */}
       <PrizesCard/>
    </Raisebox>
  );
}

export default Prizes;
 