import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import styled from "styled-components";
const colour = require("../../assets/colour");


const CenteredAnchor = styled.a`
display: flex;
justify-content: space-around;
font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
text-align: center;
padding-top: 20px;
padding-bottom : 40px;
width: 70px, 
height: 20px
`


export function HackScheduleCard(props){
   
    return(

        <CenteredAnchor
              href={props.link}
              target="_blank"
              rel="noopener noreferrer"
        >
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: colour.highlightedColour, color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #b643e6' }}
            >
                <h3 className="vertical-timeline-element-title">Registration Starts</h3>
                <h4 className="vertical-timeline-element-subtitle">27 th June 2021</h4>
                <p> 
                    Hurry Before you miss it !!
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: colour.highlightedColour, color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid #b643e6' }}
            >
                <h3 className="vertical-timeline-element-title">Registration Ends</h3>
                <h4 className="vertical-timeline-element-subtitle">6th July 2021</h4>
                <p>
                    Registration will end at 23:59 Hrs
                </p> 
            </VerticalTimelineElement> 
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: colour.highlightedColour, color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #b643e6' }}
            >
                <h3 className="vertical-timeline-element-title">Hackathon Starts</h3>
                <h4 className="vertical-timeline-element-subtitle">9th July 2021</h4>
                <p>
                    Your first code commit starts here 
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: colour.highlightedColour, color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  #b643e6' }}
            >
                <h3 className="vertical-timeline-element-title">Hackathon Ends</h3>
                <h4 className="vertical-timeline-element-subtitle">11 th July 2021 </h4>
                <p>
                    We will be excited to see your solutions
                </p>
            </VerticalTimelineElement>
  
        </VerticalTimeline>
    </CenteredAnchor>

    );
}





