import React from "react";
import {
  FaFacebook,
  FaInstagram,
  // FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./footerElement";

function Footer() {
  return (
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <WebsiteRights>
            {" "}
            <a href="https://ieee-jaduniv.in/" style={{ color: "#FFFFFF" }}>
              IEEE Jadavpur University SB
            </a>{" "}
            © {new Date().getFullYear()} All rights reserved.
          </WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href="https://www.facebook.com/ieeejusb"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.instagram.com/_ieeeju/"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href="https://twitter.com/IeeeJadavpur"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink
              href="https://www.linkedin.com/company/ieee-ju/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
      <br />
      <span style={{ color: "#FFFFFF", paddingBottom: '10px' }}>
        Made with ❤️ by Technical Team, IEEE JU SB  <a href="https://github.com/Hackodisha/hackodisha-web" style={{color: '#fff'}} target="_blank" rel="noreferrer">Template by HackOdisha</a>
      </span>
      <br />
    </FooterContainer>
  );
}

export default Footer;
