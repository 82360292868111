import styled from "styled-components";

export const Raisebox = styled.div`
min-height: 70vh;
width: 90vw;
margin: 10vh auto;
background: rgba(24,25,26,0.96);
box-shadow: 2vw 2vw 2vw 1vw rgba(0, 0, 0, 0.6);
border-radius: 10px;
`;

export default Raisebox

