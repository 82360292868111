import React from "react";
import Raisebox from "../components/raisebox/raisebox";
import {HackScheduleCard } from "../components/hackschedule";
import './style.css';
const colour = require("../assets/colour");




function Schedule() {
  return (
    <Raisebox>
      <div className="schedule_container">
        <h1 className="schedule_h1">
          HACKATHON <span style={{ color: colour.highlightedColour }}> SCHEDULE</span>
       </h1> </div>
       <HackScheduleCard/>
    </Raisebox>
  );
}

export default Schedule;
 